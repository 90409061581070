import React, { useEffect, useState } from "react";
import { NavLink } from 'react-router-dom';
import Swal from "sweetalert2";
import bannerLogo from '../../assets/images/CGC_Login_logo.png';
import { getAuthState, setAuthState } from "../../assets/scripts/login-util";
import { logout } from "../api/loginAPI";
import favImg from '../../assets/images/ic_group.png'
import AddMember from "../teeTime/addMember";


const Header = (props) => {

    const [username,    setUsername]   = useState('User');
    const [showModal,   setShowModal] = useState(false);
    const [count,       setCount] = useState(3); // Example state for count
    const [showTooltip, setShowTooltip] = useState(false); // State to manage tooltip visibility

    const handleMouseOver = () => {
        setShowTooltip(true);
    };

    const handleMouseOut = () => {
        setShowTooltip(false);
    };
    useEffect(() =>{
        const cData = getAuthState()
        const { user } = cData; 

        if(!!user) setUsername(user.userName)
    },[])
  
    const logoutHandler = async () => {
		try {
			await logout()
			setAuthState();
            window.open('/', '_self');
		} catch (err) {
			window.open('/', '_self');
			Swal.fire({
				icon              : "error",
				titleText         : "Error!",
				text              :  err.message,
				buttonsStyling    : false,
				confirmButtonClass: "btn btn-brand",
			});
		}
	};

    return(
        <>
        {/*  Navigation */}
            <nav className="navbar navbar-default" role="navigation">
                <div className="container">     
                    <div className="row" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div className="col-xs-10 padding-lr0" >
                            <div className="navbar-brand">
                                <NavLink to="/">
                                    <img id="asideBrandLogo" alt="Logo" src={bannerLogo} height="35" />
                                </NavLink>
                                <div className="header_main">
                                    The Chandigarh Golf Club
                                    <small> Tee Time Booking</small>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-2 padding-l30" style={{ position: 'relative', display: 'flex', justifyContent: 'flex-end' }}>
                            {/* <div style={{ cursor: 'pointer' }} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="20px" style={{ transition: 'fill 0.3s' }} onMouseOver={e => e.currentTarget.querySelector('path').setAttribute('fill', 'black')} onMouseOut={e => e.currentTarget.querySelector('path').setAttribute('fill', '#888')}>
                                    <path fill="grey" d="M224 0c-17.7 0-32 14.3-32 32V51.2C119 66 64 130.6 64 208v18.8c0 47-17.3 92.4-48.5 127.6l-7.4 8.3c-8.4 9.4-10.4 22.9-5.3 34.4S19.4 416 32 416H416c12.6 0 24-7.4 29.2-18.9s3.1-25-5.3-34.4l-7.4-8.3C401.3 319.2 384 273.9 384 226.8V208c0-77.4-55-142-128-156.8V32c0-17.7-14.3-32-32-32zm45.3 493.3c12-12 18.7-28.3 18.7-45.3H224 160c0 17 6.7 33.3 18.7 45.3s28.3 18.7 45.3 18.7s33.3-6.7 45.3-18.7z"/>
                                </svg>
                                {count > 0 && (
                                    <div style={{ position: 'absolute', top: '-5px', right: '7px', width: '16px', height: '15px', borderRadius: '50%', backgroundColor: 'red', color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '10px' }}>
                                        {count}
                                    </div>
                                )}
                                {showTooltip && (
                                    <div style={{ position: 'absolute', top: '-5px', right: '40px', backgroundColor: 'rgba(0, 0, 0, 0.7)', color: 'white', padding: '5px', borderRadius: '3px', fontSize: '12px', zIndex: '999' }}>
                                        Notices
                                    </div>
                                )}
                            </div> */}
                        </div>
                    </div>
                </div>         
            </nav>
            {/* User */}
            <div className="container-volunteer">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12">
                            <span id="lblProfile" style={{lineHeight: "2.1" }} 
                            >Welcome <b>{username}</b></span>
                            <button id="btnLogout" onClick={logoutHandler} className="btn btn-link btn-sm pull-right"> Logout </button>
                        </div>
                    </div>
                </div>
            </div>

            {showModal && (
            <AddMember
                show            = {showModal}
                from            = {'header'}                                
                onDismissModal  = {() => setShowModal(false) }
            />
        )}

        </>
    )
}   

export default Header;