import React, { useEffect, useState } from 'react';
import Loader from '../global/loader';
import { Datepicker } from "@meinefinsternis/react-horizontal-date-picker";
import swal from 'sweetalert2';
import BookingModal from './bookingModal';
import { getTeeTimeBook, getTeeTimeInit, blockTeeTime, getBookingSheet } from '../api/teeTimeAPI';
import TeeCard from './teeCard';
import TeeSheetModal from './teeSheetModal';
import { getAuthState } from '../../assets/scripts/login-util';
import ModalCaptcha from './modalCaptcha';

const morningString = 'Morning', afternoonString = 'Afternoon';

const BookingSheet = (props) => {
    
    /**@global */ 
    var publishData;
    let IntervalId;
    const maxCount = 4;

    const [selectedDate,        setSelectedDate]        = useState();   
    const [teeTimeBook,         setTeeTimeBook]         = useState([]);
    const [teeTimeBookingSheet, setTeeTimeBookingSheet] = useState([]);
    const [shotgunBookingSheet, setShotgunBookingSheet] = useState([]);
    const [shotgunBook,         setShotgunBook]         = useState([]);
    const [type,                setType]                = useState('');
    const [holiday,             setHoliday]             = useState();
    const [BookingUsers,        setBookingUsers]        = useState([]);
    const [bookingModal,        setBookingModal]        = useState(null);

    const [showTeeSheet,        setShowTeeSheet]        = useState();
    
    const [refresh,             setRefresh]             = useState(false);
    
    const [pageData,            setPageData]            = useState();
    const [serverTime,          setServerTime]          = useState();
    const [localTime,           setLocalTime]           = useState(moment().valueOf());
    const [teeTimeStatus,       setTeeTimeStatus]       = useState();

    const [isBlocked,           setIsBlocked]           = useState(false);
    const [isRebooking,         setRebooking]           = useState();
    
    const [clickBlocked,        setClickBlocked]        = useState(false);
    const [clickList ,          setClickList]           = useState([]);
    const [captcha,             setCaptcha]             = useState(false);
    const [bookDetails,         setBookDetails]         = useState({});

    useEffect(()=> { 
        fetchTeeTimeInit()
    
        setTimeout(()=> {  //scroll to last date           
            const element = document.getElementsByClassName('dr');         
            if(element.length > 0) element[0].scrollLeft += 200;            
        }, 230)    
        
        
        //Refetch teetimeinit on window resume
        document.addEventListener("visibilitychange", (f) => {
            clearInterval(IntervalId);
            if(document.visibilityState == "visible") fetchTeeTimeInit();            
        });

        return () => clearInterval(IntervalId); //Cleanup effect    
    }, []);
    
    useEffect(()=> {      
        if(selectedDate){ 
            fetchTeeTimeBook(selectedDate) 
        }
    }, [selectedDate, refresh]);   
  
    //Listen to events from server -->
    useEffect(()=> { 
        if(!!props?.socketMaster){
            props.socketMaster.on('refresh-teesheet', (res) => {
                const {data} = res;                                          
                if(data){
                    if(data.Action == 'Refresh') return refetchTeeTime();
                                        
                    let isDateSame = true;
                                        
                    setSelectedDate(d => {
                        isDateSame = moment(moment(d).format('YYYY-MM-DD')).isSame(data.BookDate);                           
                        return d
                    })

                    if(!isDateSame) return;
                  
                    if((data?.TeeTimeBook.length > 0) ||(data?.ShotgunBook.length > 0)){
                        if(data.Type === 'Shotgun')
                            setShotgunBook(data.ShotgunBook);
                        else
                            setTeeTimeBook(data.TeeTimeBook);
                    }
                
                    //Block /UnBlock User Clicks    
                    if(!!data.Action && data.Action == 'Block'){ 
                        setIsBlocked(true);                                                                                       
                    }else if(data.Action == 'Release'){
                        setIsBlocked(false);                   
                    }           
                                                
                    // setBookingUsers(data.BookingUsers);
                }
            });
        }       
    },[props]);   
   
    const fetchTeeTimeInit = async(refetch)=> {     
        try {

            const { TeeTimeValidation, ServerDate } = await getTeeTimeInit();
            publishData = TeeTimeValidation;
                
            initTimer(ServerDate);
            setPageData({TeeTimeValidation, ServerDate});                       

            if(!refetch) setSelectedDate(moment(ServerDate).add( TeeTimeValidation.AdvanceBookingDays, 'd').toDate());                                                                     
        } catch (err) {            
            swal.fire({ icon: "error",titleText: "Error!",text: err.message })
            return;             
        }
    }
 
    const fetchBookingSheet = async(params) => {
        let dateToFetch = params; 
                                                
        /**@listens - selectedDate < serverDate -> sends serverDate || selected Date */
        if((pageData?.ServerDate) && moment(dateToFetch).isBefore(pageData.ServerDate))
            dateToFetch = pageData?.ServerDate;
                    
        const response = await getBookingSheet(moment(dateToFetch).format('YYYY-MM-DD'));
        setBookingUsers(response.BookingUsers)
        setShowTeeSheet(true)
    };

    const fetchTeeTimeBook = async(params)=> {      
        try {
                       
            let dateToFetch = params; 
                                                
            /**@listens - selectedDate < serverDate -> sends serverDate || selected Date */
            if((pageData?.ServerDate) && moment(dateToFetch).isBefore(pageData.ServerDate))
                dateToFetch = pageData?.ServerDate;
                        
            const response = await getTeeTimeBook(moment(dateToFetch).format('YYYY-MM-DD'));
            response.Type =='Slot' ? setTeeTimeBookingSheet(response.BookingSheet) : setShotgunBookingSheet(response.BookingSheet)

          
            setTeeTimeStatus(response.Status);
            setType(response.Type);
            setHoliday(response.Holiday);
             
            setIsBlocked(response?.UserBooking);
            setRebooking(response?.Rebooking);    
                             
            if(response.Status === 'publish'){                
                // setBookingUsers(response.BookingUsers)
                if(response.Type === 'Shotgun'){
                    setShotgunBook(response.ShotgunBook)
                }else{
                    setTeeTimeBook([...response.TeeTimeBook])
                }
            }else if( response.Status === 'closed'){
                setHoliday(response.LongMessage);
                setShotgunBook([]);
                setTeeTimeBook([]);
            }
        } catch (err) {
            swal.fire({icon: "error",titleText: "Error!",text: err.message});
            return;  
        }
    }
   
    const initTimer = (time)=> {       
        if(time){          
            setServerTime(moment(time));
            //setInterval for servertime -->
            IntervalId  = setInterval(()=> {                                
                let LocalTimeDiff = 0;

                setLocalTime( l => {
                    const newLocalTime = moment().valueOf();
                    LocalTimeDiff      = moment(newLocalTime).diff(moment(l));                  
                    return newLocalTime;
                })                
             
                if(LocalTimeDiff > 3000){   // if serverTime falls behind - refetch serverTime                  
                    clearInterval(IntervalId);                 
                    fetchTeeTimeInit();
                }else{                                  
                    setServerTime(t => {                                   
                        const unixTS      = moment(t, "DD-MM-YYYY hh:mm:ss A").add(1, 'second').valueOf();
                        const displayTime = moment(unixTS).format('DD-MM-YYYY hh:mm:ss A');

                        return displayTime;                                    
                    });                                       
                }                              
            }, 1000);
        }
    }

    const onBookSelect = async(book, captcha = false)=> {
        try {

            const user = getAuthState('user');
            const day = moment(selectedDate).format('dddd');
            const isWeekend = (day == 'Saturday') || (day == 'Sunday') || holiday;

            if(user.MembershipCategory == 'Mid-Week Member' && isWeekend){
                swal.fire({
                    icon: 'warning',
                    titleText: 'Booking not available for Mid-Week Members',
                    showConfirmButton: false,
                    timer: 2000,
                });
                return
            }
            if(book.Status === "FCFS"){ //block if cancelled slot is not open 
                swal.fire({
                    icon: 'warning',
                    titleText: `Available for First Come First Serve`,
                    showConfirmButton: false,
                    timer: 2000,
                });
                return;
            }
            //disable if book time is passed-              
            const isAfter = moment(serverTime, 'DD-MM-YYYY hh:mm:ss A').isAfter( moment(`${moment(selectedDate).format("DD-MM-YYYY")} ${book.Slot}`, 'DD-MM-YYYY hh:mm A'));
            if(isAfter) return;
            if(checkBookingStatus(book)) { //checks if user is blocked.
                if(book.Status === "Cancelled"){ //block if cancelled slot is not open 
                    swal.fire({
                        icon: 'warning',
                        titleText: `Available for booking one day prior at ${ (book.Session == morningString)? pageData.TeeTimeValidation.RebookingMorningTime : pageData.TeeTimeValidation.RebookingAfternoonTime}`,
                        showConfirmButton: false,
                        timer: 2000,
                    });
                    return;
                }
                swal.fire({ icon: 'warning', timer: 2000, titleText:'Your booking exist!', showConfirmButton: false})
                return;                       
            }
            
            if(!!book.ID || (!!book.Status && book.Status !== 'Cancelled'))  return;  //checks if booked by other user.                  
            
            book.BookDate  = moment(selectedDate).format("YYYY-MM-DD");

            const response = await blockTeeTime({...book, MembershipCategory : user.MembershipCategory, captcha});                        
            book.ID        = response.BookID;
            swal.close();

            setBookingModal(book);                        
        } catch (err) { 
            swal.close();   
            if(err.message == 'Error: PrimeTime')  {
                swal.fire({
                    icon: 'warning',
                    titleText: 'Mid-Week Members are not allowed to book during Prime time',
                    showConfirmButton: false,
                    timer: 2000,
                });
                return
            }else if(err.message == 'Error: Tee Time already blocked by another member' || err.message == 'Tee Time already blocked by another member')  {
                swal.fire({
                    toast: true,
                    icon: 'warning',
                    titleText: 'Tee Time already blocked by another member',
                    position: 'center',
                    showConfirmButton: false,
                    timer: 2000,
                    animation: false,
                    customClass: {
                        popup: 'margin-20',
                    },
                });
            }else if(err.message == 'Error: Open Captcha')  {
                setBookDetails(book);
                setCaptcha(true)
            }else{
                swal.fire({icon: "error",titleText: "Error!",text: err.message});            
            }
        }       
    }
   
    const dateChange     = (value)=> setSelectedDate(value[0]);
    const refetchTeeTime = ()=> setRefresh((r) => !r);

    const checkBookingStatus = (book) => {       
        let isDisabled = false;

        //disable if book time is passed - Update for shotgun             
        const isAfter = moment(serverTime, 'DD-MM-YYYY hh:mm:ss A').isAfter( moment(`${moment(selectedDate).format("DD-MM-YYYY")} ${book.Slot}`, 'DD-MM-YYYY hh:mm A'));
        
        if(isAfter)   isDisabled = true;  //Combine With OTR    
        if(isBlocked) isDisabled = true;

        if(book.Status === 'Cancelled' && !!isRebooking){
            if( (book.Session === morningString && !isRebooking?.isMorningSessionOpen) || (book.Session === afternoonString && !isRebooking?.isAfternoonSessionOpen) ){               
                isDisabled = true;     
            }
        }
         
        return isDisabled;
    }      
     
    const onRefresh =()=> {               
        const clickTime = moment().valueOf();        

        setClickList(d => {                    
            if(d.length == maxCount) d = d.slice(1);                
            return [...d, clickTime];
        });             
                
        let isValid = true;

        if(clickList.length >= maxCount) {
            const t         = clickList.at(clickList.length - 3);           
            const clickDiff = moment(clickTime).diff(moment(t));                    
            if(clickDiff <= 1000){ 
                swal.fire({ icon: 'warning', timer: 1500 , showConfirmButton: false, allowOutsideClick: false,
                    titleText: 'Too many request ..!', text: 'Please Wait',  
                });

                isValid = false;
                setClickBlocked(true);
                setTimeout(()=> {setClickBlocked(false)}, 3000);                
            }                          
        }  

        if(isValid && !clickBlocked) refetchTeeTime();
    }
    
    return (  
        <>
            <div className="row sticky-format padding-0">
                <div className='col-xs-12'>
                                        
                    {(!!selectedDate) &&(
                        <div className='row'>
                            <div id="datepicker-containter" className='col-xs-12'>                               
                                <Datepicker                                   
                                    startDate   = {!!pageData? moment(pageData.ServerDate).toDate(): moment().toDate()}                                                
                                    startValue  = {selectedDate}
                                    endValue    = {selectedDate}                             
                                    endDate     = {!!pageData? moment(pageData.ServerDate).add( pageData.TeeTimeValidation.AdvanceBookingDays || 1, 'd').toDate(): moment().add(1, 'd').toDate()}                              
                                    onChange    = {dateChange}                              
                                />
                            </div>
                        </div>
                    )}
                    
                    <div className="row margin-t20">
                        <div className="col-xs-12 text-center">
                            <h6 id="txtServerTime" className="margin-0"> { serverTime && (moment(serverTime, "DD-MM-YYYY hh:mm:ss A").format('h:mm:ss A'))} </h6>
                        </div>
                    </div>

                    {/* Info */}
                    {(!!holiday) && (
                        <div id="pnlHoliday" className="row margin-tb5">
                            <div className="col-xs-12 text-center">
                                <h6 className="margin-tb0" style={{color: '#d15b47'}}><span id="lblHoliday"> { holiday.Name } </span></h6>
                            </div>
                        </div>
                    )}                   

                    <div className="row margin-tb10">                
                        <div className="col-xs-12 text-center">
                            <button id="btnBookSheet" type="button" className="btn btn-sm btn-primary t-none padding-lr15" 
                                disabled={clickBlocked} onClick={onRefresh}
                            > <i className='fa fa-refresh margin-r5'></i> Refresh </button>             
                        </div>
                    </div>
                    <div className="row margin-tb10">                
                        <div className="col-xs-12 text-center">
                            <ul  className='padding-l15'>
                                <li style={{display: 'inline', marginRight: '10px'}}> <span className='slotDot' style={{backgroundColor: '#368800'}}></span>Available</li>
                                <li style={{display: 'inline', marginRight: '10px'}}> <span className='slotDot' style={{backgroundColor: 'rgb(102 102 102 / 70%)'}}></span>Booked</li>
                                <li style={{display: 'inline', marginRight: '10px'}}> <span className='slotDot' style={{backgroundColor: 'rgb(224 137 0 / 66%)'}}></span>Inprocess</li>
                                <li style={{display: 'inline', marginRight: '10px'}}> <span className='slotDot' style={{backgroundColor: 'rgb(4 106 189 / 60%)'}}></span>Cancelled</li>
                                <li style={{display: 'inline', marginRight: '10px'}}> <span className='slotDot' style={{backgroundColor: '#046abd'}}></span>Available</li>
                                <li style={{display: 'inline', marginRight: '10px'}}> <span className='slotDot' style={{backgroundColor: 'rgb(135 6 6 / 60%)'}}></span>FCFS</li>
                            </ul>                        
                        </div>
                    </div>

                    <div className="row margin-tb5">                                          
                        <div className='col-xs-6'>
                            <h6 className="text-muted text-center text-uppercase sticky-format dotLine"> <b>1st Tee</b> </h6>
                        </div>
                        <div className='col-xs-6'>
                            <h6 className="text-muted text-center text-uppercase sticky-format dotLine"> <b>10th Tee</b> </h6>
                        </div>
                    </div>
                    
                </div>    
            </div>
            
            {/* Listing  */}
            <div className="row padding-5">
                <div className='container'>
                    <div className="col-xs-12">                
                        {(teeTimeStatus === 'publish' && ((type == 'Slot' && (teeTimeBook.length > 0))|| (type == 'Shotgun' && (shotgunBook.length > 0))))?(                           
                            <div id="divTeeTime" className="row">
                                <div className='col-xs-5'>
                                    <div className="row">
                                        {teeTimeBook.filter(f => f.TeeBox == 1).map((m, i) => (
                                            <TeeCard
                                                key = {i}
                                                book = {m}
                                                onBookSelect = {(m) => onBookSelect(m)}
                                                isBookingAllowed = { (checkBookingStatus(m))}
                                            />
                                        ))}
                                    </div>
                                </div>
                                <div className="col-xs-2"></div>
                                <div className='col-xs-5'>
                                    <div className="row">
                                        {teeTimeBook.filter(f => f.TeeBox == 10).map((m, i) => (
                                            <TeeCard
                                                key = {i}
                                                book = {m}
                                                onBookSelect = {(m) => onBookSelect(m)}
                                                isBookingAllowed = { (checkBookingStatus(m))}
                                            />
                                        ))}
                                    </div>      
                                </div>
                            </div>
                        ):(<>                            
                            <div className="panel panel-default panel-club fadeInUp">
                                <div className="row wow fadeInUp animated animated">
                                    <div className="col-xs-12">
                                        <h4 className="text-center text-uppercase text-muted margin-tb50">
                                            {teeTimeStatus === 'closed' ? 'Course Closed': 'Tee Time Not Published' }
                                        </h4>
                                    </div>
                                </div>
                            </div>    
                        </>)}
                    </div>            
                </div>
            </div>
            
            {(teeTimeStatus === 'publish') && (
                <div className="row margin-t10">
                    <div className="col text-center">
                        <button id="btnBookSheet" type="button" className="btn btn-sm btn-success"
                            onClick={() => fetchBookingSheet(selectedDate)}
                        > Booking Sheet </button>
                    </div>
                </div>
            )}                                

            {!!bookingModal && (
                <BookingModal
                    show                    = {!!bookingModal }
                    action                  = {'a'}
                    teetimeUsers            = {props.teetimeUsers}
                    onDismissModal          = {()=> setBookingModal(null) }
                    teeTimeBookData         = {bookingModal}
                    SelectedDate            = {moment(selectedDate).format('YYYY-MM-DD')}
                    refetchTeeTime          ={()=> refetchTeeTime()}
                    refetchActiveMembers    ={()=> props.refetchActiveMembers()}
                />
            )}
           
           {!!showTeeSheet && (
                <TeeSheetModal
                    show           = {showTeeSheet}
                    teeSheet       = {(type == 'Shotgun')? shotgunBookingSheet : teeTimeBookingSheet}
                    bookingUsers   = {BookingUsers}
                    selectedDate   = {moment(selectedDate).format('DD-MM-YYYY')}
                    onDismissModal = {()=> setShowTeeSheet(null)}                                 
                />
            )}
           {!!captcha && (
                <ModalCaptcha
                    show           = {captcha}
                    onDismissModal = {()=> setCaptcha(false)}         
                    bookDetails    = {bookDetails}       
                    onBookSelect   = {onBookSelect}                 
                />
            )}
        </>
    );
};

export default BookingSheet;
